import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { useGetPhpMyAdminSso } from 'api/site';
import { Box, CircularProgress, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const PHPMyAdmin: FC = () => {
  const { siteId = '' } = useParams<{ siteId: string }>();
  const { data: fetchPhpMyAdminSSO } = useGetPhpMyAdminSso(siteId);

  const { t } = useTranslation();

  if (fetchPhpMyAdminSSO) {
    window.location.href = fetchPhpMyAdminSSO.data.result.phpmyadmin_sign_on_url;
  }

  return (
    <Box
      data-testid="hello"
      sx={{
        position: 'fixed !important',
        display: 'flex',
        flexFlow: 'column',
        left: '50%',
        padding: '1rem',
        textAlign: 'center',
        top: '50% !important',
        transform: 'translate(-50%, -50%)',
        '& > *:first-of-type': {
          margin: '0 auto 1rem auto',
        },
      }}
    >
      <CircularProgress />
      <Typography>{t('redirect_label')}</Typography>
    </Box>
  );
};

export default PHPMyAdmin;
